import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CustomizeConfiguration = () => {
  return (
    <LayoutComponent>
      <h3>Customize Configuration</h3>
    </LayoutComponent>
  )
}

export default CustomizeConfiguration
